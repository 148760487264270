import { useState } from 'react';
import { message, Upload } from 'antd';
import axios from 'axios';
import { API_URL } from '../helpers/utils';
import icons from '../Assets/mobileSvg';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { Device } from '@capacitor/device';
import imageCompression from 'browser-image-compression';

export const version = '25.01.22.01';

// export const useImageUpload = (uploadPath, limit) => {
//   const [fileList, setFileList] = useState([]);

//   const handleUpload = async ({ file, onSuccess, onError, onProgress }) => {
//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('uploadPath', uploadPath);

//     try {
//       const response = await axios.post(`${API_URL}/upload-file?uploadPath=${uploadPath}`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//         onUploadProgress: (progressEvent) => {
//           const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
//           onProgress({ percent: percentCompleted }, file);

//           setFileList((prevFileList) =>
//             prevFileList.map((item) => {
//               if (item.uid === file.uid) {
//                 return { ...item, percent: percentCompleted };
//               }
//               return item;
//             })
//           );
//         },
//       });

//       if (response.status === 200) {
//         message.success(`${file.name} file uploaded successfully`);
//         onSuccess('ok');
//         setFileList((prevFileList) =>
//           prevFileList.map((item) => {
//             if (item.uid === file.uid) {
//               return { ...item, url: response.data.fullFilePath };
//             }
//             return item;
//           })
//         );
//       } else {
//         message.error(`${file.name} file upload failed.`);
//         onError(new Error('Upload failed'));
//       }
//     } catch (error) {
//       onError(error);
//       message.error(`${file.name} file upload failed: ${error.message}`);
//     }
//   };

//   const handleRemove = async (file) => {
//     try {
//       const response = await axios.delete(`${API_URL}/upload-file?uploadPath=${file.url}`, {
//         data: { url: file.url },
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (response.status === 200) {
//         message.success(`${file.name} file deleted successfully`);
//         setFileList((prevFileList) => prevFileList.filter((item) => item.uid !== file.uid));
//       } else {
//         message.error(`${file.name} file delete failed.`);
//       }
//     } catch (error) {
//       message.error(`${file.name} file delete failed: ${error.message}`);
//     }
//   };

//   const uploadProps = {
//     customRequest: handleUpload,
//     multiple: true,
//     fileList,
//     beforeUpload: (file) => {
//       const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
//       if (!isJpgOrPng) {
//         message.error('You can only upload JPG/JPEG/PNG file!');
//         return Upload.LIST_IGNORE;
//       }
//       if (limit && fileList.length >= limit) {
//         message.error(`You can only upload up to ${limit} files.`);
//         return Upload.LIST_IGNORE;
//       }

//       return true;
//     },
//     onChange: ({ fileList: newFileList }) => {
//       if (limit) {
//         setFileList(newFileList.slice(0, limit));
//       } else {
//         setFileList(newFileList);
//       }
//     },
//     onRemove: handleRemove,
//     listType: 'picture',
//   };

//   return { uploadProps, fileList, setFileList };
// };

export const useImageUpload = (uploadPath, limit) => {
  const [fileList, setFileList] = useState([]);

  const compressImage = async (file) => {
    const options = {
      // maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error('Image compression error:', error);
      message.error('Image compression failed. Uploading original image.');

      return file; // Return the original file if compression fails
    }
  };

  const handleUpload = async ({ file, onSuccess, onError, onProgress }) => {
    try {
      const compressedFile = await compressImage(file);
      // console.log('Original File Size:', (file.size / 1024 / 1024).toFixed(2), 'MB');
      // console.log('Compressed File Size:', (compressedFile.size / 1024 / 1024).toFixed(2), 'MB');
      const formData = new FormData();
      formData.append('file', compressedFile);
      formData.append('uploadPath', uploadPath);

      const response = await axios.post(`${API_URL}/upload-file?uploadPath=${uploadPath}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          onProgress({ percent: percentCompleted }, file);

          setFileList((prevFileList) =>
            prevFileList.map((item) => {
              if (item.uid === file.uid) {
                return { ...item, percent: percentCompleted };
              }
              return item;
            })
          );
        },
      });

      if (response.status === 200) {
        message.success(`${file.name} file uploaded successfully`);
        onSuccess('ok');
        setFileList((prevFileList) =>
          prevFileList.map((item) => {
            if (item.uid === file.uid) {
              return { ...item, url: response.data.fullFilePath };
            }
            return item;
          })
        );
      } else {
        message.error(`${file.name} file upload failed.`);
        onError(new Error('Upload failed'));
      }
    } catch (error) {
      onError(error);
      message.error(`${file.name} file upload failed: ${error.message}`);
    }
  };

  const handleRemove = async (file) => {
    try {
      const response = await axios.delete(`${API_URL}/upload-file?uploadPath=${file.url}`, {
        data: { url: file.url },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        message.success(`${file.name} file deleted successfully`);
        setFileList((prevFileList) => prevFileList.filter((item) => item.uid !== file.uid));
      } else {
        message.error(`${file.name} file delete failed.`);
      }
    } catch (error) {
      message.error(`${file.name} file delete failed: ${error.message}`);
    }
  };

  const uploadProps = {
    customRequest: handleUpload,
    multiple: true,
    fileList,
    beforeUpload: (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/JPEG/PNG file!');
        return Upload.LIST_IGNORE;
      }
      if (limit && fileList.length >= limit) {
        message.error(`You can only upload up to ${limit} files.`);
        return Upload.LIST_IGNORE;
      }

      return true;
    },
    onChange: ({ fileList: newFileList }) => {
      if (limit) {
        setFileList(newFileList.slice(0, limit));
      } else {
        setFileList(newFileList);
      }
    },
    onRemove: handleRemove,
    listType: 'picture',
  };

  return { uploadProps, fileList, setFileList };
};
export const ImageUploader = ({ uploadProps }) => {
  return (
    <Upload className="mobileUpload" {...uploadProps}>
      <div className="dragDrppBox text-center">
        {icons.upload}
        <div className="fw-semibold fs-6">
          <a href="#" className="text-decoration-underline">
            CLICK HERE
          </a>{' '}
          to Upload Image
        </div>
      </div>
    </Upload>
  );
};

export const DownloadImage = async (url, name, onComplete) => {
  const result = await Filesystem.checkPermissions();

  if (result.state === 'denied') {
    if (!(await Filesystem.requestPermissions())) {
      return;
    }
  }

  try {
    await Filesystem.mkdir({
      path: '/AT-Scheduling',
      directory: Directory.Documents,
    });
  } catch (error) {
    console.log(error);
  }

  Filesystem.downloadFile({
    path: '/AT-Scheduling/' + name,
    url: url,
    directory: Directory.Documents,
  })
    .then(async (res) => {
      onComplete();
      const fileOpenerOptions = {
        filePath: res.path,
      };

      await FileOpener.open(fileOpenerOptions)
        .then(() => {
          // 'File is opened'
        })
        .catch((error) => {
          console.error(error);
        });
    })
    .catch((err) => {
      onComplete();
    });
};

export const emptyMessages = {
  myshift: { title: 'No Shift Found!', subtitle: 'No shift added for this date.' },
  task: { title: 'No Tasks Found!', subtitle: 'No tasks added for this shift.' },
  progress: { title: 'No Progress Found!', subtitle: 'No progress added for this shift.' },
  goal: { title: 'No Goals Found!', subtitle: 'No goals added for this shift.' },
};

export const getAddressFromLatLong = (lat, lng) => {
  return new Promise((resolve, reject) => {
    if (!window.google || !window.google.maps) {
      reject('Google Maps API not loaded.');
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    const latlng = new window.google.maps.LatLng(lat, lng);

    geocoder.geocode({ latLng: latlng }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          resolve(results[0].formatted_address);
        } else {
          reject('No results found');
        }
      } else {
        reject(`Geocoder failed due to: ${status}`);
      }
    });
  });
};

export const calculateDistance = (origin, destination) => {
  return new Promise((resolve, reject) => {
    if (!window.google || !window.google.maps) {
      return reject(new Error('Google Maps JavaScript API is not loaded.'));
    }

    const service = new window.google.maps.DistanceMatrixService();

    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: 'DRIVING',
        unitSystem: window.google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      },
      (response, status) => {
        if (status === 'OK') {
          const distanceInMeters = response.rows[0].elements[0].distance.value;
          const distanceInKm = distanceInMeters / 1000;
          resolve(distanceInKm);
        } else {
          reject(new Error('Error fetching distance from Google Maps API: ' + status));
        }
      }
    );
  });
};

export const isSimulator = async () => {
  const info = await Device.getInfo();
  console.log('Device Info:', info);
  return info.isVirtual;
};
